body {
  background-color: #282c34;
  min-height: 100vh;
}

.card:hover {
  border: 2px solid #fdd000;
}

#footer {
  background-color: #212327;
  color: #ffffff;
  padding: 0px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.site-footer-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-identity {
  text-align: center;
}

.site-title a {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}


.CardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card {
  background-color: #282c34;
  border-radius: 15px;
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  padding: 22px;
  width: 100%;
  max-width: 600px;
}

.titleInput {
  margin-bottom: 16px;
}

.descriptionInput {
  flex-grow: 1;
  margin-right: 16px;
}

.specificationsInput {
  margin-bottom: 16px;
}

.budgetInput {
  margin-bottom: 16px;
}

.referencesInput {
  flex-grow: 1;
}

.deadlineInput {
  margin-bottom: 16px;
}

.attachmentsInput {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.attachmentsInput label {
  cursor: pointer;
}

.contactInput {
  margin-bottom: 16px;
}

.submitButton {
  margin-top: 16px;
}
